<template>
  <q-field :model-value="modelValue" color="black" :rules="rules">
    <template v-slot:control>
      <template v-if="multiple">
        <q-chip size="sm" v-for="v in display_value" :label="v" :key="v"/>
      </template>
      <template v-else>
        <q-item-label lines="1">
          {{ display_value }}
        </q-item-label>
      </template>
    </template>
    <template v-slot:prepend>
      <q-icon name="event"/>
    </template>
    <q-popup-proxy :breakpoint="500" ref="dialog" transition-show="scale"
                   transition-hide="scale">
      <q-date minimal ref="picker" no-unset :multiple="multiple" :range="range"
              :rules="rules"
              :options="validate_date" :default-year-month="default_year_month"
              :mask="date_format" v-model="dates"/>
    </q-popup-proxy>
  </q-field>
</template>
<script setup>
import {ref, defineProps, watch, defineEmits, computed, onMounted} from 'vue'
import {stores} from 'src/stores'
import {
  convert_date_to_string,
  get_now,
  change_datetime_format,
  add_days_to_date,
  is_date_in_current_month,
  get_current_month_string,
  get_next_month_string
} from "src/composables/utils"

import dayjs from "dayjs"

const context_store = stores.use_context()

const props = defineProps({
  modelValue: {type: [String, Array]},
  multiple: {type: Boolean, default: false},
  range: {type: Boolean, default: false},
  rules: {type: Array},
  booking_cutoff_days: {type: Number, default: 0},
  date_format: {type: String, default: 'YYYY-MM-DD'},
  display_format: {type: String, default: 'DD/MM/YYYY'},
})

const emit = defineEmits(['update:modelValue'])

const dialog = ref(null)
const picker = ref(null)
const dates = ref(null)
const min_date = ref(convert_date_to_string(get_now().add(props.booking_cutoff_days, 'd'), 'YYYY/MM/DD'))
const default_year_month = ref(get_current_month_string())

const map_result_for_picker = val => {
  if ((props.multiple || props.range) && !Array.isArray(val)) {
    return null
  }

  const transform_min_date = min_date.value?.replaceAll?.('/', '-')

  if (props.multiple) {
    let result = []
    val.forEach(d => {
      if (props.range) {
        result.push({
          from: d[0],
          to: d[1]
        })
      }
      else {
        result.push(d)
      }
    })
    return result
  }
  if (props.range) {
    let is_behind_min_date = false
    if (val[0]) {
      is_behind_min_date = dayjs(val[0]).isBefore(dayjs(min_date.value))
    }
    if (is_behind_min_date) {
      return transform_min_date
    }
    return {
      from: val[0],
      to: val[1],
    }
  }

  if (val && dayjs(val).isBefore(dayjs(min_date.value))) {
    return transform_min_date
  }
  return val
}

const map_result_for_clients = val => {
  if (props.multiple) {
    let result = []
    val.forEach(d => {
      if (props.range) {
        result.push(
          [d.from, d.to]
        )
      }
      else {
        result.push(d)
      }
    })
    return result
  }
  if (props.range) {
    if (val.from && val.to) return [val.from, val.to]
    else return [val, add_days_to_date(val, 1)]
  }
  return val
}

const display_value = computed(() => {
  if (props.multiple) {
    if (!dates.value) return []
    let result = []
    dates.value.forEach(d => {
      if (props.range) result.push(
        `${format_date(d.from)} - ${format_date(d.to)}`
      )
      else result.push(d)
    })
    return result
  }
  if (!dates.value) return null
  if (props.range) {
    if (dates.value.from && dates.value.to) {
      return `${format_date(dates.value.from)} - ${format_date(dates.value.to)}`
    }
    return `${format_date(dates.value)} - ${format_date(add_days_to_date(dates.value, 1))}`
  }
  return format_date(dates.value)
})

const format_date = (value) => {
  return change_datetime_format(value, props.date_format, props.display_format)
}

const validate_date = (date) => {
  let result = true
  if (date < min_date.value) result = false
  return result
}

onMounted(() => {
  if (!is_date_in_current_month(min_date.value)) {
    default_year_month.value = get_next_month_string(min_date.value)
  }
  if (props.modelValue) {
    dates.value = map_result_for_picker(props.modelValue)
  }
})

watch(() => props.range, (range) => {
  if (!range) {
    if (dates.value?.from) dates.value = dates.value?.from
  }
  else {
    if (dates.value) dates.value = {
      from: dates.value,
      to: dates.value
    }
  }
})

watch(
  dates,
  val => {
    if (props.range && !dates.value.from) {
      dates.value = {from: dates.value, to: add_days_to_date(dates.value, 1)}
    }
    emit('update:modelValue', map_result_for_clients(val))
    dialog.value.hide()
  }
)

</script>
<style lang="scss">
.q-date__navigation {
  :nth-child(4) {
    display: none !important;
  }

  :nth-child(5) {
    display: none !important;
  }

  :nth-child(6) {
    display: none !important;
  }
}
</style>
