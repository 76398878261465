<template>
  <template v-if="loading_context.in_progress">
  </template>
  <template v-else>
    <embedder/>
  </template>
</template>
<script setup>
import Embedder from 'src/components/embedder'
import {use_services} from 'src/composables/services'
import {
  onBeforeMount,
  reactive
} from 'vue'
import {stores} from 'src/stores'
import {
  DEFAULT_LANGUAGE,
  EMBED_MODE, NODE_KINDS
} from 'src/constants'
import {refresh_visible_nodes, standardize_node} from 'src/composables/canvas'
import {load_language_translations, get_default_or_first_item} from 'src/composables/utils'
import {useI18n} from 'vue-i18n'


const props = defineProps({
  context_id: {type: String, required: true},
  language_code: {type: String, default: DEFAULT_LANGUAGE.code},
  currency_code: {type: String, default: ''},
  node_id: {type: String, required: true},
  access_token: {type: String},
  group: {type: String},
  custom_events: {type: Object}
})

const i18n = useI18n()

const context_store = stores.use_context()
const auth_store = stores.use_auth()
const canvas_store = stores.use_canvas()
const translation_store = stores.use_translations()

const loading_context = reactive({
  in_progress: false,
  error: null
})

context_store.$patch(state => {
  state.mode = EMBED_MODE
})

if (props.access_token) {
  auth_store.$patch(state => {
    state.user.access_token = props.access_token
  })
}

const load_context = async () => {
  const services = use_services()
  loading_context.in_progress = true
  loading_context.error = null

  let payload = {
    mode: EMBED_MODE,
    group: props.group,
    node_id: props.node_id
  }
  const response = await services.CmsService.load_context(payload)
  if (response.status === 200) {
    context_store.$patch(state => {
      state.template = response.data.template
      state.group = props.group
      state.app = response.data.app
      state.agent = response.data.agent
      state.packages = response.data.packages
      state.products = response.data.products
      state.languages = response.data.languages
      state.currencies = response.data.currencies


      const language = state.languages.find(l => l.code === props.language_code)
      if (language) state.language = language
      else state.language = get_default_or_first_item(state.languages)

      const currency = state.currencies.find(c => c.code === props.currency_code)
      if (currency) state.currency = currency
      else state.currency = get_default_or_first_item(state.currencies)
    })

    canvas_store.$patch(state => {
      let nodes = {}

      Object.keys(response.data.nodes).forEach(node_id => {
        const node_code = response.data.nodes[node_id].code
        const node_kind = NODE_KINDS[node_code]
        if (node_kind) {
          nodes[node_id] = standardize_node(response.data.nodes[node_id])

          // override custom events
          nodes[node_id].custom_events = props.custom_events
        }
      })

      state.nodes = nodes
      state.node = nodes[props.node_id]
    })

    if (response.data.accessor) {
      auth_store.$patch({
        user: response.data.accessor
      })
    }
    else {
      auth_store.$patch(state => {
        state.editor.access_token = null
        state.user.access_token = null
      })
    }
    refresh_visible_nodes(props.context_id)
  }
  else {
    loading_context.error = response.data.error
  }
  loading_context.in_progress = false
}

onBeforeMount(async () => {
  await load_context()

  const services = use_services()

  await load_language_translations({
    context_store,
    translation_store,
    services,
    i18n
  })
})

</script>
<style lang="scss" src="src/spa/css/variables.scss"></style>
<style lang="scss" src="src/css/app.scss"></style>
<style lang="scss" src="src/css/quasar.scss"></style>
