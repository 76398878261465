<template>
  <div v-if="payload" :style="styles">
    <hotel-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      @submit="handle_submit"
      :styles="styles"
      :color="color"
      :design="design"
      :hotel="packages['hotel']"
      :product="products['hotel']"
      :node="node"
      v-if="product_combine === 'hotel'"
    />
    <flight-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      @submit="handle_submit"
      :flight="packages['flight']"
      :product="products['flight']"
      :color="color"
      :design="design"
      :node="node"
      v-else-if="product_combine === 'flight'"
    />
    <bundle-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      @submit="handle_submit"
      :color="color"
      :design="design"
      :bundle="packages['flight--hotel']"
      :product="products['flight']"
      :node="node"
      v-else-if="product_combine === 'flight--hotel'"
    />
    <tour-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      @submit="handle_submit"
      :color="color"
      :design="design"
      :tour="packages['tour']"
      :product="products['tour']"
      :node="node"
      v-else-if="product_combine === 'tour'"
    />
    <transfer-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      :color="color"
      :design="design"
      @submit="handle_submit"
      :transfer="packages['transfer']"
      :product="products['transfer']"
      :node="node"
      v-else-if="product_combine === 'transfer'"
    />
    <car-rental-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      :color="color"
      :design="design"
      @submit="handle_submit"
      :car_rental="packages['car_rental']"
      :product="products['car_rental']"
      :node="node"
      v-else-if="product_combine === 'car_rental'"
    />
    <tour-package-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      :color="color"
      :design="design"
      @submit="handle_submit"
      :tour_package="packages['tour_package']"
      :product="products['tour_package']"
      :node="node"
      v-else-if="product_combine === 'tour_package'"
    />
    <div v-else>
      <q-item-label class="text-center" caption>
        Not supported for this combination
      </q-item-label>
    </div>
  </div>
</template>
<script setup>
import { defineProps, onMounted , computed } from 'vue'
import {use_services} from 'src/composables/services'
import { stores } from "src/stores"
import qs from "qs";
import HotelForm from './hotel'
import FlightForm from './flight'
import BundleForm from './bundle'
import TourForm from './tour'
import TransferForm from './transfer'
import CarRentalForm from './car-rental'
import TourPackageForm from './tour-package'
import keyBy from 'lodash.keyby';

const services = use_services()


const props = defineProps({
  styles: { type: Object },
  color: { type: String },
  packages: { type: Object },
  products: { type: Object },
  product_combine: { type: String },
  handle_submit: { type: Function },
  default_destination: { type: Object },
  flight_campaign: { type: String },
  partner_id: { type: String },
  design: {type: String},
  node: {type: Object}
})

const package_store = stores.use_package();
const payload = computed(()=> package_store.payload?.[props.node?.id])

onMounted(() => {
  if (!package_store.payload[props.node.id]) {
    package_store.payload[props.node.id] = {
      flight: {
        travelers: [],
        cabin_class: null,
        dates: null,
        dp_airport: null,
        ar_airport: null,
        form: null,
        fl_round_trip: true
      },
      hotel: {
        place: null,
        travelers: [],
        dates: null
      },
      bundle: {
        flight_dates: null,
        hotel_dates: [],
        travelers: [],
        dp_airport: null,
        ar_airport: null,
        cabin_class: null,
        stars: null,
        form_data: {}
      },
      tour: {
        place: null,
        travelers: [],
        dates: null,
      },
      transfer: {
        airport: null,
        destination: null,
        from_airport: true,
        round_trip: true,
        pickup_date: null,
        pickup_time: null,
        return_date: null,
        return_time: null,
        travelers: [],
      },
      car: {
        driver_age: 30,
        pickup_location: null,
        pickup_date: null,
        pickup_time: null,
        dropoff_data: {
          dropoff_location: null
        },
        diff_dropoff: null,
        dropoff_date: null,
        dropoff_time: null,
      },
      tour_package: {
        place: null,
        travelers: [],
        dates: null
      }
    }
  }else{
    check_cache_value(package_store.payload[props.node.id])
  }
})

const check_travelers_value = (travelers) => {
  travelers.forEach(traveler => {
    if (!('type' in traveler) || !traveler.type || !['adult', 'child', 'infant'].includes(traveler.type)) {
      traveler.type = 'adult';
    }
    if (!('age' in traveler) || !traveler.age || !(Number(traveler.age) > 0)) {
      traveler.age = 18;
    }
    if (!('room' in traveler) || !traveler.room || !(Number(traveler.room) > 0)) {
      traveler.room = 1;
    }
    if (typeof traveler.room === 'string') traveler.room = Number(traveler.room)
    if (typeof traveler.age === 'string') traveler.age = Number(traveler.age)
  });
  return travelers
}

const check_cache_value = (cache) => {
  Object.values(cache).forEach(property => {
    if (property && Array.isArray(property.travelers)) {
      check_travelers_value(property.travelers)
    }
  });
}

const get_package_query = async () => {
  const queryString = location.search.substring(1)
  if (queryString) {
    const query = qs.parse(queryString)
    if (query.flight) {
      const response = await services.AppService.list_places({
        search_text: `${query?.flight?.dp_airport},${query?.flight?.ar_airport}`,
        page: 1,
        per_page: 2,
        types: 'airport',
        language_code: 'en-US'
      })
      const airports = keyBy(response.data.places, 'code')
      package_store.payload[props.node.id].flight = {
        travelers: check_travelers_value(query?.flight?.travelers) || [],
        cabin_class: query?.flight?.cabin_class,
        dates: check_date_time(query?.flight?.dates),
        dp_airport: airports?.[query?.flight?.dp_airport],
        ar_airport: airports?.[query?.flight?.ar_airport],
        form: null,
        fl_round_trip: query?.flight?.fl_round_trip === "true"
      }
    }

    if (query.hotel) {
      const response = await services.AppService.list_places({
        search_text: query?.hotel?.place,
        page: 1,
        per_page: 1,
        types: 'multi_city_vicinity,airport',
        language_code: 'en-US'
      })

      package_store.payload[props.node.id].hotel = {
        place: response.data.places?.[0],
        travelers: check_travelers_value(query?.hotel?.travelers) || [],
        dates:  check_date_time(query?.hotel?.dates) || null,
      }
    }
    if (query.bundle) {
      const response = await services.AppService.list_places({
        search_text: `${query?.bundle?.dp_airport},${query?.bundle?.ar_airport}`,
        page: 1,
        per_page: 2,
        types: 'airport',
        language_code: 'en-US'
      })
      const airports = keyBy(response.data.places, 'code')
      package_store.payload[props.node.id].bundle = {
        flight_dates: check_date_time(query?.bundle?.flight_dates) || [],
        hotel_dates: check_date_time(query?.bundle?.hotel_dates) || [],
        travelers: check_travelers_value(query?.bundle?.travelers) || [],
        dp_airport: airports?.[query?.bundle?.dp_airport] || null,
        ar_airport: airports?.[query?.bundle?.ar_airport] || null,
        cabin_class: query?.bundle?.cabin_class,
        stars: +query?.bundle?.stars || null,
        form_data: {}
      }
    }
    if (query.tour) {
      const response = await services.AppService.list_places({
        search_text: query?.tour?.place,
        page: 1,
        per_page: 2,
        types: 'airport',
        language_code: 'en-US'
      })
      package_store.payload[props.node.id].tour = {
        place: response.data.places?.[0],
        travelers: check_travelers_value(query?.tour?.travelers) || [],
        dates: check_date_time(query?.tour?.dates) ||[],
      }
    }
    if (query.tour_package) {
      const response = await services.AppService.list_places({
        search_text: query?.tour_package?.place,
        page: 1,
        per_page: 2,
        types: 'airport',
        language_code: 'en-US'
      })
      package_store.payload[props.node.id].tour_package = {
        place: response.data.places?.[0],
        travelers: check_travelers_value(query?.tour_package?.travelers) || [],
        dates: check_date_time(query?.tour_package?.dates) || '',
      }
    }
    if (query.car) {
      const response = await services.AppService.list_places({
        search_text: `${query?.car?.pickup_location},${query?.car?.dropoff_data?.dropoff_location}`,
        page: 1,
        per_page: 2,
        types: 'airport',
        language_code: 'en-US'
      })
      const airports = keyBy(response.data.places, 'code')
      package_store.payload[props.node.id].car = {
        driver_age: query?.car?.driver_age || 30,
        pickup_location: airports?.[query?.car?.pickup_location] || null,
        pickup_date: query?.car?.pickup_date,
        pickup_time: query?.car?.pickup_time,
        dropoff_data: {
          dropoff_location: airports?.[query?.car?.dropoff_data?.dropoff_location] || null,
        },
        diff_dropoff: query?.car?.diff_dropoff === 'true',
        dropoff_date: query?.car?.dropoff_date,
        dropoff_time: query?.car?.dropoff_time,
      }
    }
    if (query.transfer) {
      const response = await services.AppService.list_places({
        search_text: query?.transfer?.airport,
        page: 1,
        per_page: 1,
        types: 'airport',
        language_code: 'en-US'
      })

      const response2 = await services.AppService.list_places({
        search_text: query?.transfer?.destination,
        page: 1,
        per_page: 1,
        types: 'city,point_of_interest',
        language_code: 'en-US'
      })

      package_store.payload[props.node.id].transfer = {
        airport: response.data.places?.[0],
        destination: response2.data.places?.[0],
        round_trip:  query?.transfer?.diff_dropoff === 'true',
        pickup_date: query?.transfer?.pickup_date,
        pickup_time:query?.transfer?.pickup_time,
        return_date:query?.transfer?.return_date,
        return_time: query?.transfer?.return_time,
        travelers:  check_travelers_value(query?.transfer?.travelers)|| [],
      }
    }
  }
}

get_package_query();



</script>
