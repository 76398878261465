<template>
  <q-form class="q-pa-sm" v-if="payload" :key="JSON.stringify(payload.dates)" @submit="submit" greedy>
    <div class="q-row q-col-gutter-sm">
      <div :class="className">
        <place-select has_code dense v-model="payload.place" :types="place_types" :rules="rules.place" outlined hide-bottom-space
          fill-input hide-selected prepend_icon="place" :label="$t('common.destination')" />
      </div>
      <div :class="className">
        <date-picker dense outlined hide-bottom-space :booking_cutoff_days="tour_package.booking_cutoff_days"
          :rules="rules.dates" v-model="payload.dates" :label="$t('common.departure')" />
      </div>
      <div :class="className">
        <traveler-select dense v-model="payload.travelers" :rules="rules.travelers"
          :max_adults="+traveler_limits?.['adult']?.limit" :max_infants="+traveler_limits?.['infant']?.limit"
          :max_children="+traveler_limits?.['child']?.limit" :traveler_limit_type="tour_package?.traveler_limit_type"
          :total_traveler_limit="tour_package?.total_traveler_limit" :traveler_types="product.traveler_types"
          :default_traveler_count="tour_package?.default_traveler_count" outlined hide-bottom-space with_rooms
          :label="$t('common.travelers')" />
      </div>
      <div :class="className">
        <search-btn :label="$t('common.search-now')" :color="color" />
      </div>
    </div>
  </q-form>
</template>
<script setup>
import { computed, defineEmits,ref} from 'vue'
import PlaceSelect from 'src/components/common/place-select/index.vue'
import DatePicker from 'src/components/common/date-picker/index.vue'
import TravelerSelect from 'src/components/common/traveler-select/index.vue'
import SearchBtn from './search-btn/index.vue'
import { stores } from "src/stores"
import { DESKTOP_VIEWPORT } from "src/constants"
import { onMounted } from 'vue'

const props = defineProps({
  styles: { type: Object },
  color: { type: String },
  flight_campaign: { type: String },
  partner_id: { type: String },
  default_destination: { type: Object },
  node: { type: Object }
})


const package_store = stores.use_package();
const context_store = stores.use_context()
const payload = computed(()=> package_store.trip_form[props.node?.id])


const className = computed(() => {
  if (context_store.viewport === DESKTOP_VIEWPORT) {
    return 'q-col-3'
  } else {
    return 'q-col-12'
  }
})

const emit = defineEmits(['submit'])

const traveler_limits = [
  {
    adult: {
      traveler_type: "adult",
      limit: 9
    },
    child: {
      traveler_type: "child",
      limit: 6
    },
    infant: {
      traveler_type: "infant",
      limit: 6
    }
  }
]
const tour_package = {
  booking_cutoff_days:6,
  total_traveler_limit: 9,
  traveler_limit_type: "per_type",
}

const product = {
  traveler_types: ['adult', 'infant', 'child'],
}

const place_types = computed(() => {
  return [
    'province_state',
    'multi_city_vicinity',
    'airport',
    'city',
    'country'
  ]
})

const rules = {
  dates: [
    val => !!val || 'Required'
  ],
  place: [
    val => !!val || 'Required'
  ],
  travelers: [],
}

const submit = () => {
  const {
    travelers,
    place,
    dates
  } = payload.value || {}

  emit('submit', {
    process: "multileg_holiday",
    currency_code: context_store.currency?.code,
    language_code: context_store.language?.code,
    travelers: JSON.stringify(travelers),
    expectation: JSON.stringify({
      is_separate: false,
      mh_des_code: place?.id,
      mh_des_type: 'place_id',
      mh_start_date: dates,
    }),
    // flight_campaign: props.flight_campaign,
    // partner_id: props.partner_id,
  })
}
onMounted(()=>{
  if (!package_store.trip_form[props.node.id]) {
    package_store.trip_form[props.node.id] = {
      place: null,
      dates: null,
      travelers:[]
    }
  }
})

</script>
