import {ref} from 'vue'


export default {
  id: 'canvas',
  setup: () => {

    const pages = ref([])
    const page = ref(null)
    const nodes = ref({})
    const nested_nodes = ref([])
    const flattened_nodes = ref({})
    const node = ref(null)
    const el = ref(null)

    const screen_width = ref(0)

    const page_rect = ref({
      top: 0,
      left: 0,
      width: 0,
      height: 0
    })

    const submitting_forms = ref({})

    return {
      nodes,
      nested_nodes,
      flattened_nodes,
      el,
      screen_width,
      page_rect,
      pages,
      page,
      node,
      submitting_forms,
    }
  }
}
