import {
  EMBED_MODE,
  FOOTER_CODE,
  FORM_NODE_CODE,
  GRID_NODE_CODE,
  SLIDESHOW_NODE_CODE,
  HEADER_CODE,
  LAYOUT_TYPE,
  NODE_ID_ATTRIBUTE,
  NODE_TYPE_ATTRIBUTE,
  CE_TAG,
  NODE_SIDES,
  VISIBILITY_LOGIN,
  VISIBILITY_NOT_LOGIN,
  DESKTOP_VIEWPORT,
  TABLET_VIEWPORT, MOBILE_VIEWPORT,
} from 'src/constants'
import {stores} from 'src/stores'
import {
  flatten_nodes,
  nest_nodes,
  get_node_descendants
} from 'src/composables/utils'
import {Set} from 'core-js/internals/set-helpers'
import {colors} from 'quasar'
import {NODE_KINDS} from 'src/constants'
import deepmerge from 'src/composables/utils/deepmerge'

export const standardize_node = data => {
  const node_code = data.code
  const node_kind = NODE_KINDS[node_code]
  if (!node_kind) throw new Error(`Unknown node code: ${node_code}`)
  const standard_node = node_kind.generate()

  return deepmerge(
    standard_node, data,
    {
      arrayMerge (target, source, options) {
        let result = []
        source.forEach((item, index) => {
          if (typeof target[index] === 'undefined') {
            result[index] = options.cloneUnlessOtherwiseSpecified(item, options)
          }
          else {
            if (options.isMergeableObject(item)) {
              result[index] = deepmerge(target[index], item, options)
            }
            else {
              result[index] = options.cloneUnlessOtherwiseSpecified(item, options)
            }
          }
        })
        return result

      }
    }
  )
}

export const get_dom_node = node_id => {
  const canvas_store = stores.use_canvas()
  return canvas_store.el.querySelector(`[${NODE_ID_ATTRIBUTE}="${node_id}"]`)
}


export const get_node_rect_on_canvas = node_id => {
  const canvas_store = stores.use_canvas()
  const dom_node = get_dom_node(node_id)
  if (!dom_node) return null

  let result = {
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  }

  const canvas_rect = canvas_store.el.getBoundingClientRect()
  const node_rect = dom_node.getBoundingClientRect()

  // node's coordinates relatively with canvas
  result.width = node_rect.width
  result.height = node_rect.height
  result.x = node_rect.left - canvas_rect.left
  result.y = node_rect.top - canvas_rect.top

  // for node sides
  result.top = result.y
  result.left = result.x
  result.right = result.x + result.width
  result.bottom = result.y + result.height

  return result

}

export const get_node_el = node_id => {
  const canvas_store = stores.use_canvas()
  if (!canvas_store.el) return node_id
  return canvas_store.el.querySelector(`[${NODE_ID_ATTRIBUTE}="${node_id}"]`)
}

export const get_parent_node_el = el => {
  if (el.parentNode.hasAttribute(NODE_TYPE_ATTRIBUTE)) return el.parentNode
  return get_parent_node_el(el.parentNode)
}

export const is_node_visible = (node_id, context_id = null) => {
  const context_store = stores.use_context(context_id)
  const canvas_store = stores.use_canvas(context_id)
  const auth_store = stores.use_auth(context_id)

  const node = canvas_store.nodes[node_id]
  if (!node) return false

  if (!node.visibility) return true

  if (context_store.mode !== EMBED_MODE) {
    if (node.page_id !== canvas_store.page.id) return false
  }

  let auth_condition
  if (auth_store.user.access_token) {
    auth_condition = VISIBILITY_LOGIN
  }
  else {
    auth_condition = VISIBILITY_NOT_LOGIN
  }

  let node_auths = node.visibility.auths
  let node_viewports = node.visibility.viewports

  if (!node_auths.includes(auth_condition)) return false
  if (!node_viewports.includes(context_store.viewport)) return false

  if (node.code === FOOTER_CODE && canvas_store.page.hide_footer) {
    return false
  }

  if (node.code === HEADER_CODE && canvas_store.page.hide_header) {
    return false
  }

  return true
}


export const get_node_layout = node_id => {
  const canvas_store = stores.use_canvas()
  const node = canvas_store.flattened_nodes[node_id]
  if (!node.parent_id && node.type !== LAYOUT_TYPE) return null
  if (node.type === LAYOUT_TYPE) return node
  return get_node_layout(node.parent_id)
}

export const get_node_from_el = el => {
  const canvas_store = stores.use_canvas()

  const node_id = el.getAttribute(NODE_ID_ATTRIBUTE)
  return canvas_store.flattened_nodes[node_id]
}


export const get_form_node_from_child = child => {
  const canvas_store = stores.use_canvas()
  if (!child.parent_id) return null
  const parent = canvas_store.flattened_nodes[child.parent_id]
  if ([
    FORM_NODE_CODE
  ].includes(parent.code)) return parent
  return get_form_node_from_child(parent.id)
}


export const get_targeted_node_el = el => {
  if (el.isSameNode(document)) return null
  if (el.hasAttribute(NODE_TYPE_ATTRIBUTE)) return el
  return get_targeted_node_el(el.parentNode)
}

export const get_targeted_node_el_from_pointer = (x, y, excludes = null) => {
  const canvas_store = stores.use_canvas()
  const dom_nodes = Array.from(canvas_store.el.querySelectorAll(
    `[${NODE_TYPE_ATTRIBUTE}]`
  ))
  let els_from_pointer = document.elementsFromPoint(x, y)
  if (els_from_pointer.length && els_from_pointer[0].tagName.toLowerCase() === CE_TAG) {
    const cms_custom_element = els_from_pointer[0]
    els_from_pointer = cms_custom_element.shadowRoot.elementsFromPoint(x, y)
  }
  els_from_pointer = els_from_pointer.filter(
    el => dom_nodes.includes(el)
  )
  if (excludes) els_from_pointer = els_from_pointer.filter(el => !excludes.includes(el))
  if (els_from_pointer.length) return get_targeted_node_el(els_from_pointer[0])
  return null
}


export const generate_common_node_styles = node => {
  const canvas_store = stores.use_canvas()
  let parent = null
  if (node.parent_id) {
    parent = canvas_store.flattened_nodes[node.parent_id]
  }

  const dimension = node.dimension || {}

  const styles = node.styles || {}

  let result = {
    'position': 'relative'
  }

  if (parent) {
    if (parent.layout.columns.enable) {
      result['width'] = '100%'
    }
    else {
      const width = node.dimension.width
      const height = node.dimension.height
      if (width) result['width'] = `${width}px`
      if (height) result['height'] = `${height}px`
      if (dimension.min_width) {
        result['min-width'] = `${dimension.min_width}px`
      }
    }
  }

  if (dimension.min_height) {
    result['min-height'] = `${dimension.min_height}px`
  }

  if (styles) {
    const corners = styles.corners
    if (corners?.enable && ![
      GRID_NODE_CODE,
      SLIDESHOW_NODE_CODE
    ].includes(node.code)) {
      result['border-radius'] = corners.values.map(v => `${v}px`).join(' ')
    }

    const border = styles.border
    if (border?.enable) {
      result['outline-style'] = 'solid'
      result['outline-width'] = `${border.width || 0}px`
      result['outline-offset'] = `-${border.width || 0}px`
      const {r, g, b} = colors.hexToRgb(border.color)
      const border_opacity = (border.opacity || 0) / 100
      result['outline-color'] = `rgba(${r}, ${g}, ${b}, ${border_opacity})`
    }

    const shadow = styles.shadow
    if (shadow?.enable) {
      const box_shadow = [
        '0px',
        `${shadow.distance}px`,
        `${shadow.blur}px`,
        `${shadow.size}px`,
      ]
      const {r, g, b} = colors.hexToRgb(shadow.color)
      box_shadow.push(
        `rgba(${r}, ${g}, ${b}, ${(shadow.opacity || 0) / 100})`
      )
      result['box-shadow'] = box_shadow.join(' ')
    }

    const margin = styles.margin
    if (margin) {
      Object.keys(NODE_SIDES).forEach(side => {
        const side_margin = margin[side]
        if (side_margin) {
          let margin_value
          if (side_margin.auto) {
            margin_value = 'auto'
          }
          else {
            margin_value = `${side_margin.value || 0}px`
          }
          result[`margin-${side}`] = margin_value
        }
      })
    }
    const padding = styles.padding
    if (padding) {
      Object.keys(NODE_SIDES).forEach(side => {
        const side_padding = padding[side]
        if (side_padding) {
          result[`padding-${side}`] = `${side_padding.value || 0}px`
        }
      })
    }

  }

  return result

}

export const get_visible_nodes = (context_id = null) => {
  const canvas_store = stores.use_canvas(context_id)
  const context_store = stores.use_context(context_id)

  let invisible_node_ids = new Set()

  let nodes = Object.values(canvas_store.nodes)

  if (context_store.mode !== EMBED_MODE) {
    nodes = nodes.filter(
      n => n.page_id === canvas_store.page.id
    )
  }

  nodes.forEach(n => {
    if (!is_node_visible(n.id, context_id)) {
      invisible_node_ids.add(n.id)
      get_node_descendants(n.id, nodes).forEach(cn => {
        invisible_node_ids.add(cn.id)
      })
    }
  })

  const result = {}

  nodes.forEach(
    n => {
      if (!invisible_node_ids.has(n.id)) {
        result[n.id] = n
      }
    }
  )
  return result
}

export const refresh_visible_nodes = (context_id = null) => {
  const canvas_store = stores.use_canvas(context_id)

  const visible_nodes = get_visible_nodes(context_id)

  let result = nest_nodes(visible_nodes)

  canvas_store.$patch(state => {
    state.nested_nodes = result
    state.flattened_nodes = flatten_nodes(result)
  })
}

export const refresh_node_parent_ids = nested_nodes => {
  const handle = (node, parent = null) => {
    if (!node.parent_ids) node.parent_ids = []
    if (parent) {
      node.parent_ids = [...parent.parent_ids, parent.id]
    }
    if (node.children) {
      node.children.forEach(child => handle(child, node))
    }
  }

  nested_nodes.forEach(n => handle(n))

  return nested_nodes
}

export const get_cursor_position_on_canvas = (x, y) => {
  const canvas_store = stores.use_canvas()

  const canvas_rect = canvas_store.el.getBoundingClientRect()

  return {
    x: x - canvas_rect.x,
    y: y - canvas_rect.y,
  }
}

