<template>
  <q-field
    ref="el"
    :label="label"
    :model-value="modelValue"
    :loading="fetching"
    @blur="handle_on_blur"
    @focus="handle_on_focus"
    @click="handle_on_click"
    :stack-label="!!modelValue"
  >
    <template v-slot:prepend v-if="prepend_icon">
      <q-icon :name="prepend_icon" />
    </template>
    <template v-slot:control>
      <q-item-label class="full-width" lines="1">
        <div v-if="context_store.viewport === MOBILE_VIEWPORT">
          {{ search_text }}
        </div>
        <input
          v-else
          class="no-wrap full-width egs-select-inputs"
          v-model="search_text"
          @input="handle_search_changes"
          ref="search_input"
        />
      </q-item-label>
    </template>

    <q-menu
      :key="`${search_text}-${options.length}`"
      ref="dropdown"
      no-parent-event
      fit
      no-refocus
      no-focus
      max-height="90vh"
      anchor="bottom left"
    >
      <list-place
        :fetching="fetching"
        :options="options"
        :model-value="modelValue"
        @select="select_option"
        @loadmore="fetch_more_options"
      />
    </q-menu>

    <q-popup-proxy
      class="egs-mb-popup"
      :no-parent-event="true"
      :breakpoint="680"
      ref="dialog"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card
        style="width: 100vw; height: 100vh; max-height: 100vh; max-width: 100vw"
      >
        <q-item>
          <q-space />
          <div class="full-width text-h6 text-capitalize q-my-xs">
            {{ label }}
          </div>
          <q-btn class="egs-close-button" dense color="grey-8" flat icon="close" @click="hide_dialog" />
        </q-item>
        <q-separator style="height: 5px" />
        <q-field
          autofocus
          borderless
          :stack-label="!!modelValue"
          :label="label"
          :loading="fetching"
          class="q-px-md"
        >
          <template v-slot:prepend v-if="prepend_icon">
            <q-icon :name="prepend_icon" />
          </template>
          <template v-slot:control>
            <q-item-label class="full-width" lines="1">
              <input
                autofocus
                class="no-wrap full-width egs-select-inputs"
                v-model="search_text"
                @input="handle_search_changes"
                ref="search_input_2"
              />
            </q-item-label>
          </template>
        </q-field>
        <q-separator style="height: 5px" />
        <q-card-section class="q-pa-none">
          <list-place
            separator
            :options="options"
            :model-value="modelValue"
            @select="select_option"
            :fetching="fetching"
            @loadmore="fetch_more_options"
          />
        </q-card-section>
      </q-card>
    </q-popup-proxy>
  </q-field>
</template>
<script setup>
import { ref, onBeforeMount, onMounted, watch } from 'vue'
import axios from 'axios'
import debounce from 'lodash.debounce'
import groupBy from 'lodash.groupby'
import { use_services } from 'src/composables/services'
import { handle_page_scroll } from 'src/composables/utils'
import { stores } from 'src/stores'
import {
  DESKTOP_VIEWPORT,
  MOBILE_VIEWPORT,
  TABLET_VIEWPORT
} from 'src/constants'
import ListPlace from './list-place.vue'

let CancelToken = axios.CancelToken
let cancel

const props = defineProps({
  types: { type: Array, default: () => [] },
  modelValue: { type: [Object, Array, String] },
  prepend_icon: { type: String },
  static_route_enabled: { type: Boolean },
  destination_type: { type: String },
  longitude: { type: String },
  latitude: { type: String },
  property_included: { type: Boolean, default: false },
  cities_with_airports: { type: Boolean, default: false },
  search_required: { type: Boolean, default: false },
  departure_code: { type: String },
  arrival_code: { type: String },
  has_code: { type: Boolean },
  is_properties: { type: Boolean, default: false },
  ancestors: { type: Array },
  label: { type: String },
  disable_loadmore: { type: Boolean },
  with_properties: { type: Boolean },
  hotel_places: { type: Boolean },
  place_id:{type:String}
})

const emit = defineEmits(['update:modelValue'])
const context_store = stores.use_context()

const dropdown = ref(null)
const el = ref(null)
const search_input = ref(null)
const search_input_2 = ref(null)
const dialog = ref(false)

const search_text = ref('')

const services = use_services()

const fetching = ref(false)
const options = ref([])
const page = ref(1)
const can_loadmore = ref(true)
const per_page = ref(20)

const select_option = (option) => {
  emit('update:modelValue', option)
  search_text.value = option.name
  hide_dropdown()
  hide_dialog()
}

const handle_on_focus = async () => {
  if (!options.value.length) await fetch_options()
  show_dropdown()
  show_dialog()
}

const handle_ancestors_change = async () => {
  search_text.value = ''
  await fetch_options()
  props.modelValue = null
  show_dropdown()
}

const handle_on_click = () => {
  if (search_input.value) {
    search_input.value.select()
  }
}

const handle_on_blur = ($event) => {
  setTimeout(() => {
    hide_dropdown()
    if (props.modelValue) search_text.value = props.modelValue.name
    else search_text.value = ''
  }, 250)
}

const show_dropdown = () => {
  if ([DESKTOP_VIEWPORT, TABLET_VIEWPORT].includes(context_store.viewport)) {
    if (dropdown.value) {
      dropdown.value.show()
    }
  }
}

const hide_dropdown = () => {
  if (dropdown.value) {
    dropdown.value.hide()
  }
}

const show_dialog = () => {
  if (context_store.viewport === MOBILE_VIEWPORT) {
    dialog.value.show()
    search_input.value?.blur?.()
    setTimeout(() => {
      search_input_2.value.focus()
      search_input_2.value.select()
    }, 700)
  }
}

const hide_dialog = () => {
  dialog.value.hide()
  setTimeout(() => {
    search_input.value?.blur?.()
  }, 300)
}

const fetch_places = async (search, page) => {
  cancel?.()
  const payload = {
    search_text: search,
    types: props.types.join(','),
    language_code: 'en-US',
    longitude: props.longitude,
    latitude: props.latitude,
    property_included: props.property_included,
    has_airports: props.has_airports,
    per_page: per_page.value,
    has_code: props.has_code,
    page,
    with_properties: props.with_properties
  }
  if (props.static_route_enabled && props.destination_type) {
    payload.destination_type = props.destination_type || null
  }
  if (props.destination_type) {
    payload.destination_type = props.destination_type
  }
  if (props.departure_code) {
    payload.departure_code = props.departure_code
  }
  if (props.arrival_code) {
    payload.arrival_code = props.arrival_code
  }
  try {
    const response = await services.AppService.list_places(payload, {
      cancel_token: new CancelToken(function executor(c) {
        cancel = c
      })
    })
    if (response.status === 200) {
      if (props.hotel_places) {
        options.value = [...response.data.places, ...response.data.properties]
        return
      }
      if (page === 1) {
        options.value = response.data.places
        if (response.data?.places?.length < per_page.value)
          can_loadmore.value = false
      }
    }
  } catch (error) {
    console.log('error', error)
  }
}

const fetch_cities_with_airports = async (search, page) => {
  cancel?.()
  const payload = {
    search_text: search,
    language_code: 'en-US',
    per_page: per_page.value,
    page: page
  }
  if (props.static_route_enabled && props.destination_type) {
    payload.destination_type = props.destination_type || null
  }
  if (props.destination_type) {
    payload.destination_type = props.destination_type
  }
  if (props.departure_code) {
    payload.departure_code = props.departure_code
  }

  if (props.arrival_code) {
    payload.arrival_code = props.arrival_code
  }
  try {
    const response = await services.AppService.list_cities_with_airports(
      payload,
      {
        cancel_token: new CancelToken(function executor(c) {
          cancel = c
        })
      }
    )
    if (response.status === 200) {
      if (page === 1) {
        options.value = response.data
        if (response.data?.length < per_page.value) can_loadmore.value = false
      } else if (!response?.data?.length) can_loadmore.value = false
      else options.value = options.value.concat(response.data)
    }
  } catch (error) {
    console.log('error', error)
  }
}

const handle_search_changes = debounce(async () => {
  if (props.search_required) {
    if (!search_text.value || search_text.value.length < 3) return
  }
  await fetch_options()
  show_dropdown()
}, 200)

const fetch_properties = async (search, page) => {
  cancel?.()

  const payload = {
    search_text: search,
    language_code: 'en-US',
    per_page: per_page.value,
    place_id: props.place_id,
    page
  }
  if (props.static_route_enabled && props.destination_type) {
    payload.destination_type = props.destination_type || null
  }
  if (props.destination_type) {
    payload.destination_type = props.destination_type
  }
  if (props.departure_code) {
    payload.departure_code = props.departure_code
  }
  if (props.arrival_code) {
    payload.arrival_code = props.arrival_code
  }
  try {
    const response = await services.AppService.list_properties(payload, {
      cancel_token: new CancelToken(function executor(c) {
        cancel = c
      })
    })
    if (response.status === 200) {
      if (page === 1) {
        options.value = response.data?.result
        if (response.data?.result?.length < per_page.value)
          can_loadmore.value = false
      } else if (!response.data?.result?.length) can_loadmore.value = false
      else options.value = options.value.concat(response.data?.result)
    }
  } catch (error) {
    console.log('error', error)
  }
}

watch(
  () => props?.ancestors,
  (data) => {
    if (props.is_properties) {
      handle_ancestors_change()
    }
    return
  }
)

const fetch_options = async () => {
  fetching.value = true
  page.value = 1
  can_loadmore.value = true

  if (props.cities_with_airports) {
    await fetch_cities_with_airports(search_text.value, 1)
  } else if (props.is_properties) {
    await fetch_properties(search_text.value, 1)
  } else {
    await fetch_places(search_text.value, 1)
  }
  fetching.value = false
}

const fetch_more_options = async () => {
  if (props.disable_loadmore) return
  if (fetching.value || !can_loadmore.value) return
  if (options.value.length < per_page.value) return
  const newPage = page.value + 1
  page.value = newPage
  fetching.value = true
  if (props.cities_with_airports) {
    await fetch_cities_with_airports(search_text.value, newPage)
  } else if (props.is_properties) {
    await fetch_properties(search_text.value, newPage)
  } else {
    await fetch_places(search_text.value, newPage)
  }
  fetching.value = false
}

onBeforeMount(() => {
  if (props.modelValue) {
    search_text.value = props.modelValue.name
  }
})

onMounted(() => {
  el.value.$el.onclick = handle_on_click
  handle_page_scroll(() => {
    hide_dropdown()
  })
})
</script>
